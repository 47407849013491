<template>
  <v-card>
    <StandardCardHeader
      :go-to-link="'admin-stock-category-create'"
      :title="'Material categories'"
      :button-text="'Create'"
      :show-action-button="false"
      :link-button-color="'success'"
    />
    <v-row class="px-2 ma-0">
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('Type to search')"
          clearable
          outlined
          hide-details
          dense
          class="tender-search mb-4"
          @keyup="getDataFromApi"
          @click:clear.prevent="resetSearch()"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-select
          v-model="statusFilter"
          :placeholder="$t('Status filter')"
          :items="$store.state.baseStatuses"
          item-value="key"
          :item-text="item => $t(item.name)"
          outlined
          dense
          clearable
          hide-details
          class="mb-4"
          @change="getDataFromApi"
        ></v-select>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selectedRows"
      dense
      :headers="tableColumns"
      :items="stockCategoryListTable"
      :items-per-page="getItemsPerPageFromLS()"
      :options.sync="options"
      :server-items-length="totalDiaries"
      :loading="loading"
      :no-results-text="$t('noResults')"
      :no-data-text="$t('noResults')"
      :footer-props="{
        'items-per-page-text': '#',
        'items-per-page-options': [10, 20, 50, 100],
        'page-text': `{0}-{1} ${$t('of')} {2}`
      }"
    >
      <template #[`item.name`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <router-link
              :to="{ name:'admin-stock-category-edit', params: { id: item.id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </template>

      <template #[`item.status`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            {{ $t(`${item.status}`) }}
          </div>
        </div>
      </template>

      <template #[`item.actions`]="{item}">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              :to="{ name:'admin-stock-category-edit', params: { id: item.id } }"
              link
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiFileDocumentEditOutline }}
                </v-icon>
                <span>{{ $t('Edit') }}</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!item.deleted_at"
              link
            >
              <v-list-item-title @click.prevent="deleteCategory(item.id)">
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
                <span>{{ $t('Delete') }}</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="item.deleted_at"
              link
            >
              <v-list-item-title @click.prevent="restoreCategory(item.id)">
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiFileRestoreOutline }}
                </v-icon>
                <span>{{ $t('Restore') }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import searchMixin from '@/mixins/searchMixin'
import { getItemsPerPageFromLS } from '@/services/localStorageService'

export default {
  components: { StandardCardHeader },
  mixins: [searchMixin],
  data() {
    const selectedRows = []
    const stockCategoryListTable = []
    const totalDiaries = 0
    const loading = true
    const options = {}

    const tableColumns = [
      {
        text: this.$t('Name'),
        value: 'name',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
      },
    ]

    return {
      options,
      loading,
      statusFilter: '',
      tableColumns,
      stockCategoryListTable,
      selectedRows,
      totalDiaries,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  methods: {
    getItemsPerPageFromLS,
    getDataFromApi() {
      localStorage.setItem('itemsPerPage', `${this.options.itemsPerPage}`)
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'id'
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : 'true'
      const search = this.searchQuery ?? ''
      const status = this.statusFilter ?? ''
      axiosIns.get(`/admin/stock-categories?page=${this.options.page}&perPage=
      ${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&status=${status}`)
        .then(res => {
          this.totalDiaries = res.data.stockItemCategories.total
          this.stockCategoryListTable = res.data.stockItemCategories.data
          this.loading = false
        })
        .catch()
    },
    deleteCategory(id) {
      axiosIns.delete(`/admin/stock-categories/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restoreCategory(id) {
      axiosIns.put(`/admin/stock-categories/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>
